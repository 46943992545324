import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import Image from "../components/image"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Image />
  </Layout>
)

export default NotFoundPage

